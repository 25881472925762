<template>
         <navigation-view />
   <section>
      <div class="w-full px-5 pb-5">
        <div class="max-w-7xl mx-auto w-full">
          <ol
            class="flex gap-2 items-center pt-24 pl-3 font-normal text-[#001c54]"
          >
            <li><a href="/">Home</a></li>
            <li>> Disclosures</li>
          </ol>
          <div
            class="relative my-8 overflow-x-auto shadow-md sm:rounded-lg border-collapse border border-slate-300"
          >
            <table
              class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
            >
              <thead
                class="text-xs text-gray-700 uppercase bg-[#95C24D] text-white"
              >
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 border border-slate-300 w-12"
                  >
                    S.No.
                  </th>
                  <th scope="col" class="px-6 py-3 border border-slate-300">
                    Information
                  </th>
                  <th scope="col" class="px-6 py-3 border border-slate-300">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
   
                
                <tr class="odd:bg-white">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    1
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    Application submitted to UGC-DEB for offering ODL/Online programmes
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    <p>
                      <a
                        href="https://api.onlineyenepoya.com/media/brochure/HEI_Exempted-U-0250.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">View Application </span></a
                      >
                    </p>
                  </td>
                </tr>
                <tr class="odd:bg-white">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    2
                  </th>
                  <td
                    class="px-6 py-4 border border-t-0 border-b-0 border-slate-300"
                  >
                  UGC Notifications

                  </td>
                  <td class="py-2 border border-slate-300">
                   <p class="px-6 py-2 border-b border-slate-300"> <a
                      href="https://api.onlineyenepoya.com/media/brochure/UGC_Public_Notice_44038.pdf"
                      target="_blank"
                      ><span class="text-[#007bff]">Precaution to be taken by the students before enroliing in ODL and Online Programmes (September, 2023)</span></a
                    ></p>
                    <p class="px-6 py-2  border-slate-300"><a
                        href="https://api.onlineyenepoya.com/media/brochure/UGC_34472.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Precaution to be taken by the students before enroliing in ODL and Online Programmes (March 2024)</span></a
                      ></p>
                  </td>
                </tr>
                <tr class="odd:bg-white">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    3
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    Letters of recognition from UGC
                  </td>
                  <td class="py-4 border border-slate-300">
                    <p class="px-6 py-2">
                      <a
                        href="https://api.onlineyenepoya.com/media/brochure/entitled_list_2023_24.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Approval to Start Online Programmes</span>
                      </a>
                    </p>
   
                  </td>
                </tr>
                <tr class="odd:bg-white">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    4
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    National Institutional Ranking Framework (NIRF) Certificate, 2023
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    <a
                      href="https://api.onlineyenepoya.com/media/brochure/NIRF-2020 -ecertificate.jpg"
                      target="_blank"
                      ><span class="text-[#007bff]"> View Certificate</span></a
                    >
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    5
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    National Assessment and Accreditation Council (NAAC) Certificate, 2021
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    <a
                      href="https://api.onlineyenepoya.com/media/brochure/NAAC_accreditation_Certificate-2022.pdf"
                      target="_blank"
                      ><span class="text-[#007bff]"> View Certificate</span></a
                    >
                  </td>
                </tr>
               
                <tr class="odd:bg-white">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    6
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    Centre for Internal Quality and Assurance Annual Report (Online Programs), 2022-23
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    <a
                      href="https://api.onlineyenepoya.com/media/brochure/CIQA_REPORT_2022-23.pdf"
                      target="_blank"
                      ><span class="text-[#007bff]"> CIQA REPORT 2022-23.</span></a
                    >
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    7
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    Details of Teaching and Suporting Staff 
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    <a
                      href="https://api.onlineyenepoya.com/media/brochure/ODL.pdf "
                      target="_blank"
                      ><span class="text-[#007bff]"> Staff Details</span></a
                    >
                  </td>
                </tr>
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <footer-view />
</template>

<script>
import NavigationView from "../components/NavigationView.vue";
import FooterView from "../components/FooterView.vue";
export default {
    components: {
        NavigationView,
        FooterView,
    },
}
</script>

<style>

</style>