<template>
         <navigation-view />
   <section>
        <div class="w-full px-5 pb-5">
          <div class="max-w-7xl mx-auto w-full">
            <ol
              class="flex gap-2 items-center pt-24 pl-3 font-normal text-[#001c54]"
            >
              <li><a href="/">Home</a></li>
              <li>> Academic Corner</li>
            </ol>
            <div
              class="relative my-8 overflow-x-auto shadow-md sm:rounded-lg border-collapse border border-slate-300"
            >
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
              <thead
                class="text-xs text-gray-700 uppercase bg-[#95C24D] text-white"
              >
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 border border-slate-300 w-12"
                    >
                      S.No.
                    </th>
                    <th scope="col" class="px-6 py-3 border border-slate-300">
                      Information
                    </th>
                    <th scope="col" class="px-6 py-3 border border-slate-300">
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody>
              
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      1
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                      <p>
                        Calendar of Events
                      </p>
                    
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                        <p> <a
                        href="https://api.onlineyenepoya.com/media/brochure/YO_COE_July23_Semester_II.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Calendar of events for July 2023 Batch</span></a
                      ></p>
                      <p> <a
                        href="https://api.onlineyenepoya.com/media/brochure/YO_COE_Jan24_Semester_I.pdf "
                        target="_blank"
                        ><span class="text-[#007bff]">Calendar of events for Jan 2024 Batch</span></a
                      ></p>
                      <p> <a
                        href="https://api.onlineyenepoya.com/media/brochure/OL_Semester_I_ End_Semester_Examination_Time_table.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">End Semester Examination Time Table</span></a
                      ></p>
                     
                    </td>
                  </tr>
                
  
                  <tr
                    class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      2
                    </th>
                    <td class="px-6 py-4 border border-slate-300 font-semibold">
                        Self-Learming materials/e-learning Materials
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a href="https://api.onlineyenepoya.com/media/brochure/BBA_Accounting_for_Business_01.pdf " target="_blank"
                        ><span class="text-[#007bff]">Sample SLM</span></a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      3
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                      <p>Details of Students' Grievance Redressal Committee (SGRC)</p>
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a
                        href="https://api.onlineyenepoya.com/media/brochure/Grievance_Redressal_Committee-1.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Students' Grievance Redressal Committee</span></a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      4
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                      <p> Learning Management System (LMS) </p>
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a
                        href="https://lms.yenepoya.edu.in/login/index.php"
                        target="_blank"
                        ><span class="text-[#007bff]">https://lms.yenepoya.edu.in/login/index.php</span></a
                      >
                    </td>
                  </tr>
  
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      5
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                        Examination Infrastructure and Guidelines
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <p>
                        <a
                          href="https://api.onlineyenepoya.com/media/brochure/YO_ESE_Guidelines.pdf"
                          target="_blank"
                          ><span class="text-[#007bff]">YO_ESE_Guidelines</span></a
                        >
                      </p>
                     
                    </td>
                  </tr>

                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      6
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                        Program Structure 
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <p>
                        <a
                          href="https://api.onlineyenepoya.com/media/brochure/Course_Details.pdf"
                          target="_blank"
                          ><span class="text-[#007bff]">Course Matrix</span></a
                        >
                      </p>
                     
                    </td>
                  </tr>
                  <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    7
                  </th>
                  <td class="px-6 py-4 border border-slate-300">E-Samadhan</td>
                  <td class="px-6 py-4 border border-slate-300">
                    <p>
                      For e-Samadhan: <br><strong>Dr. Gangadhara Somayaji K S</strong>  <br>Registrar <br>EMail:registrar@yenepoya.edu.in
                    </p>
                   
                  </td>
                </tr>
                 
               
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <footer-view />
</template>

<script>
import NavigationView from "../components/NavigationView.vue";
import FooterView from "../components/FooterView.vue";
export default {
    components: {
        NavigationView,
        FooterView,
    },
}
</script>

<style>

</style>